'use strict';

var GoogleAnalyticsService = require('./services/GoogleAnalyticsService');
var SentryService = require('./services/SentryService');

window.inProduction = process.env.MODE === 'production';
window.googleAnalytics = new GoogleAnalyticsService();
window.sentryService = new SentryService();

// create APP
window.APP = Marionette.Application.extend({});

// setup language (for number format)
window.numeral.language('it');

// allow cookie session communication
window.proxiedSync = Backbone.sync;
Backbone.sync = function (method, model, options) {
  options = options || {};
  if (!options.crossDomain) {
    options.crossDomain = true;
  }
  if (!options.xhrFields) {
    options.xhrFields = { withCredentials: true };
  }

  return proxiedSync(method, model, options);
};

// function to detect CSS animation
// eslint-disable-next-line consistent-return
window.whichTransitionEvent = function () {
  var t; // transition type: transition, OTransition, MozTransition...
  var el = document.createElement('fakeelement');

  var transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd',
  };

  // eslint-disable-next-line no-restricted-syntax
  for (t in transitions) {
    if (el.style[t] !== undefined) {
      return transitions[t];
    }
  }
};
