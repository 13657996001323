'use strict';

var SocketService = require('./services/SocketService');

var clickAdd = {};
var clickRemove = {};

_.extend(Backbone.Model.prototype, Backbone.Validation.mixin);

window.Wethod = new APP();

window.APIURL = process.env.API_URL;
window.TEAMSELECTION = '/access/#team/selection';
window.LOGINPAGE = '/access/';
window.URL = window.location.origin;
window.DOMAIN = process.env.DOMAIN;

// polling for alerts
var pollingAlerts = function (timeout, startImmediately) {
  var requestAlerts = function () {
    $.ajax({
      method: 'GET',
      crossDomain: true,
      url: APIURL + '/dashboard/how-many-alerts',
      xhrFields: {
        withCredentials: true,
      },
      success: function (response) {
        if (response.code === 200) {
          dispatcher.trigger('dashboard:show:user:alerts', response.data.amount);
        } else {
          localStorage.setItem('pollingAlertsRunning', 0);
        }
      },
      dataType: 'json',
      complete: pollingAlerts(timeout),
    });
  };

  if (!document.hasFocus()) {
    // on focus out from tab, stop polling
    localStorage.setItem('pollingAlertsRunning', 0);
  } else {
    localStorage.setItem('pollingAlertsRunning', 1);

    if (startImmediately) {
      requestAlerts();
    } else {
      setTimeout(function () {
        requestAlerts();
      }, timeout);
    }
  }
};

/**
 * Returns if current user has created at least one of the companies he can access.
 * "Playground" is not taken into account.
 *
 * @return {boolean}
 */
var isOwner = function () {
  return Wethod.userInfo.get('companies_available')
    .filter(function (company) {
      return company.is_owner && company.name !== 'playground';
    })
    .length > 0;
};

var isIntercomMessengerVisible = function () {
  return Wethod.userInfo.get('company') === 'playground';
};

var getIntercomUser = function () {
  var user = {
    name: Wethod.userInfo.get('name') + ' ' + Wethod.userInfo.get('surname'),
    email: Wethod.userInfo.get('email'),
    user_id: Wethod.userInfo.get('usr_id'),
    company: {
      id: Wethod.userInfo.get('company'),
      name: Wethod.userInfo.get('company'),
    },
    is_owner: isOwner(),
  };
  var devPrefix = 'dev_' + user.email + '_';
  if (!inProduction) {
    user.name += ' [DEV]';
    user.user_id = devPrefix + user.user_id;
    user.company.id = devPrefix + user.company.id;
    user.company.name += ' [DEV]';
  }
  return user;
};

Wethod.navigate = function (route, options) {
  options = options || {};
  Backbone.history.navigate(route, options);
};

Wethod.setCookie = function (type, value) {
  $.cookie(type, value, {
    path: '/',
    domain: DOMAIN,
    expires: 1,
  });
};

Wethod.removeCookie = function (type) {
  $.removeCookie(type, {
    path: '/',
    domain: DOMAIN,
  });
};

var ajaxSetup = {
  statusCode: {
    301: function () {
    },
    401: function () {
      var destination = window.location.pathname + window.location.hash;
      Wethod.setCookie('entrypoint', destination);
      window.location.replace(URL + LOGINPAGE);
    },
    403: function () {
      window.location.replace(URL + LOGINPAGE);
    },
    200: function (data) {
      switch (data.code) {
        case 402:
          window.location.replace(URL + '/access/#block');
          break;
        case 410:
        case 412:
          var destination = window.location.pathname + window.location.hash;
          Wethod.setCookie('entrypoint', destination);
          window.location.replace(URL + TEAMSELECTION);
          break;
        case 451:
          window.location.replace(URL + '/access/#policy');
          break;
        case 503:
          window.location.replace(URL + '/access/#maintenance');
          break;
        default:
      }
    },
  },
};

// ajax config
$.ajaxSetup(ajaxSetup);

Backbone.$.ajaxSetup(ajaxSetup);

Highcharts.setOptions({
  lang: {
    noData: '',
  },
  noData: {
    style: {
      fontWeight: '400',
      fontSize: '12px',
      opacity: 0.5,
    },
  },
});

/**
 * Return total header height (app header + section header).
 * Can be useful for fixed positioned body components.
 *
 * @see js/modules/pipeline/project/pipeline-view.js:StructureLayoutView:placeFixedHeader
 * @return {*}
 */
Wethod.getHeaderHeight = function () {
  return $('.appHeader').outerHeight(true);
};

/**
 * Place headerEl right below app header (with fixed positioning) and use spacerEl to simulate
 * its  height in the page.
 * Useful for those sections that needs to maintain some kind of fixed actions upon a scrollable
 * list.
 *
 * @see https://docs.google.com/document/d/1nIuXCu4ZUQWIt8dVVGBeEpZrMEUhE-CUSCZu9YJLyfw/edit#heading=h.gibn1uj8i947
 *
 * @param headerEl
 * @param spacerEl
 */
Wethod.placeFixedHeader = function (headerEl, spacerEl) {
  var header = $(headerEl);
  var spacer = $(spacerEl);

  spacer.css('padding-top', header.outerHeight(true));
  header.css('top', Wethod.getHeaderHeight() + 'px');
};

/**
 * Returns direct link to the given alert's support explanation.
 * @param name {string} alert's name
 * @returns {string}
 */
Wethod.getAlertSupportLink = function (name) {
  var base = 'http://support.wethod.com/alerts/index/#';
  var fragment = name.toLowerCase()
    .replace(/[/><=+\-%,.*]/g, '')
    .replace(/[’']/g, '-')
    .replace(/\s/g, '-')
    .replace(/-{2}/g, '-');

  return base + fragment;
};

/**
 * Replaces company endpoint in URL with generic "app" and redirects there.
 */
Wethod.redirectWithoutCompany = function () {
  var location = window.location;
  var hostname = location.hostname;
  var companyEndpoint = hostname.split('.')[0];

  if (companyEndpoint === 'app') {
    return;
  }

  var protocol = location.protocol;
  var pathname = location.pathname;
  var hash = location.hash;
  var port = location.port;

  Wethod.setCookie('companyHostname', hostname);
  // Replace endpoint with generic "app" in hostname
  var destinationHostname = hostname
    .split('.')
    .map(function (item, index) {
      return index === 0 ? 'app' : item;
    })
    .join('.');

  var portItem = port === '' ? '' : ':' + port;

  var destination = protocol + '//' + destinationHostname + portItem + pathname + hash;

  window.location.replace(destination);
};

/**
 * When news banner is visible add class to body so sections content can adjust their height,
 * if needed.
 */
dispatcher.on('app:banner:open', function () {
  var bodyEl = document.getElementsByTagName('body')[0];
  bodyEl.classList.add('wethod-body--with-app-banner');
});

/**
 * When news banner is closed remove class to body so sections content can adjust their height,
 * if needed.
 */
dispatcher.on('app:banner:closed', function () {
  var bodyEl = document.getElementsByTagName('body')[0];
  bodyEl.classList.remove('wethod-body--with-app-banner');
});

// logout
Wethod.on('user:logout', function () {
  if (typeof Intercom !== 'undefined') {
    Intercom('shutdown');
  }
  Wethod.request('user:logout')
    .done(function () {
      var port = window.location.port;
      var portSegment = port === '' ? '' : ':' + port;

      Wethod.removeCookie('entrypoint');
      window.location.replace(window.location.protocol + '//app.' + DOMAIN + portSegment + LOGINPAGE);
    });
});

// app start
Wethod.on('start', function () {
  var timeoutPollAlerts = 180000; // 3 minutes
  var intercomUser = null;

  if (Wethod.userInfo) {
    // setup tab name
    document.title = Wethod.userInfo.get('company') + ' | WETHOD';

    window.socket = new SocketService(Wethod.userInfo);

    window.sentryService.setUser();

    intercomUser = getIntercomUser();
    Intercom('boot', {
      app_id: 'hxp3wz3i',
      hide_default_launcher: !isIntercomMessengerVisible(),
      email: Wethod.userInfo.get('email'),
      name: intercomUser.name,
      user_id: intercomUser.user_id,
      company: intercomUser.company,
      avatar: {
        type: 'avatar',
        image_url: Wethod.userInfo.get('pic'),
      },
      'Company owner': intercomUser.is_owner,
    });

    // polling for alert badge
    localStorage.setItem('pollingAlertsRunning', '0');
    pollingAlerts(timeoutPollAlerts, true);
    $(window)
      .on('focus', function () {
        if (Number(localStorage.getItem('pollingAlertsRunning')) === 0) {
          pollingAlerts(timeoutPollAlerts, false);
        }
      });
  } else {
    window.location.replace(URL + TEAMSELECTION);
  }

  if (!Backbone.History.started) {
    Backbone.history.start();
  }

  $(window)
    .scroll(function () {
      dispatcher.trigger('html:page:scroll');
    });
});

// ADD $('HTML') CLICK LISTENER
// Add or remove a class from an element when a click on 'html' is triggered
// onHtmlClick() will perform the action only once, so you need to add a listener every time
// that the item changes You must also add e.stopPropagation(); to the target element when clicked

// Examples:
// Wethod.onHtmlClick('#settingsMenu', 'isVisible', 'remove'); will remove class 'isVisible'
// from $('#settingsMenu')
// Wethod.onHtmlClick('#settingsMenu', 'isVisible', 'add'); will add class 'isVisible' to
// $('#settingsMenu')

Wethod.onHtmlClick = function (id, className, action) {
  switch (action) {
    case 'add':
      clickAdd[id] = className;
      break;
    default:
      clickRemove[id] = className;
      break;
  }
};

$('html')
  .click(function () {
    $.each(clickAdd, function (id, classToAdd) {
      $(id).addClass(classToAdd);
      delete (clickAdd[id]);
    });

    $.each(clickRemove, function (id, classToRemove) {
      $(id).removeClass(classToRemove);
      delete (clickRemove[id]);

      // highlight open menu tab
      dispatcher.trigger('set:active:tab', Wethod.currentApp);
    });
  });

// utility to scroll to page top
Wethod.scrollTop = function (x) {
  window.scrollTo(x, 0);
};
