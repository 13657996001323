module.exports = class SocketService {
  /**
   * @param userInfo {Backbone.Model}
   */
  constructor(userInfo) {
    this.url = process.env.SOCKET_URL;
    this.userInfo = userInfo.attributes;

    this.init();
  }

  init() {
    if (this.url) {
      this.socket = window.io(`${this.url}/${this.userInfo.company_endpoint}`, {
        withCredentials: true,
        query: {
          user: this.userInfo.usr_id,
        },
        auth: {
          user: {
            id: this.userInfo.usr_id,
            name: this.userInfo.name,
            surname: this.userInfo.surname,
            pic: this.userInfo.pic,
          },
        },
      }).on('reconnect_failed', () => {
        throw new Error('Socket.io reconnection failed');
      });
    }
  }

  emit(event, message) {
    if (this.socket) {
      return this.socket.emit(event, message);
    }
    return false;
  }

  on(event, callback) {
    if (this.socket) {
      return this.socket.on(event, callback);
    }
    return false;
  }

  off(event, callback) {
    if (this.socket) {
      return this.socket.off(event, callback);
    }
    return false;
  }
};
