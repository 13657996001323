const Sentry = require('@sentry/browser');

module.exports = class SentryService {
  constructor(options) {
    this.dsn = process.env.SENTRY_DSN;

    this.init(options);
  }

  init(options = {}) {
    if (this.dsn) {
      const config = options;
      config.dsn = this.dsn;
      Sentry.init(config);
    }
  }

  setUser() {
    if (this.dsn) {
      const info = Wethod.userInfo;

      Sentry.setUser({
        username: `${info.get('name')} ${info.get('surname')}`,
        email: info.get('email'),
        id: info.get('usr_id'),
        company: info.get('company_endpoint'),
        business_unit: info.get('current_business_unit'),
        role: info.get('roles'),
      });
    }
  }
};
