/* eslint-disable no-unused-expressions,prefer-rest-params,no-sequences */
module.exports = class GoogleAnalyticsService {
  constructor() {
    this.propertyId = process.env.GOOGLE_ANALYTICS_PROPERTY_ID;

    this.init();
  }

  init() {
    if (this.propertyId) {
      (function (i, s, o, g, r, a, m) {
        i.GoogleAnalyticsObject = r;
        i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments);
        }, i[r].l = 1 * new Date();
        a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      }(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga'));

      ga('create', this.propertyId, 'auto');
    }
  }

  trackPageView() {
    if (this.propertyId) {
      const path = Backbone.history.getPath();
      const fragment = Backbone.history.getFragment();
      const fragmentWithoutIds = fragment.replace(/\/[0-9]+/g, '');
      const url = `${path}#${fragmentWithoutIds}`;

      ga('send', 'pageview', url);
    }
  }
};
